import axios from "axios";
const KEY = "AIzaSyC3BGcuL2SytBkmJ980oeU94LWgAUg3tMU";

export default axios.create({
  baseURL: "https://www.googleapis.com/youtube/v3",
  params: {
    part: "snippet",
    type: "video",
    maxResults: 5,
    key: `${KEY}`,
  },
});
